<template>
    <div class="profile-password-change-view ViewWrapper">
        <clv-head-meta :title="$t('Password Change')"></clv-head-meta>
        <div class="container">
            <div class="row">
                <div class="col-sm-9 col-md-7 col-lg-5 mx-auto">

                    <common-box-logo></common-box-logo>

                    <!-- BODY -->
                    <div class="gk-card gk-card--core-style-1 mb-5">

                        <!-- LOADER 2020-02-04 -->
                        <element-loading :active="loading" :background-color="'rgba(255, 255, 255, 0.9)'"></element-loading>

                        <!-- TITLE -->
                        <div class="mb-4">
                            <p class="mb-0 text-center weight-3 size-4">{{ $t('Password Change') }}</p>
                        </div>

                        <!-- CHANGE ERROR -->
                        <div class="alert alert-danger C-Alert" role="alert" v-if="changeError">
                            <p class="mb-0">{{ $t('Password Change Failed' ) }}</p>
                            <p class="mb-0 small">{{ $t('Make sure that the current password is correct') }}</p>
                        </div>

                        <!-- THE FORM -->
                        <form class="cltlr-form-label-style-1" v-on:submit.prevent="submit" data-vv-scope="passwordChangeForm">
                            <div class="form-group">
                                <label for="password">{{ $t('Password') }}</label>
                                <input type="password" class="form-control" name="vv_password" id="password" autofocus v-validate="'required|min:3|max:50'" v-model="formFields.password">
                                <span class="small text-danger">{{ errors.first('passwordChangeForm.vv_password') }}</span>
                            </div>
                            <div class="form-group">
                                <label for="newPassword">{{ $t('New Password') }}</label>
                                <input type="password" class="form-control" id="newPassword" name="vv_newPassword" ref="vv_newPassword" v-validate="'required|min:8|max:64|password_strength'" v-model="formFields.newPassword">
                                <span class="small text-danger" v-show="errors.has('passwordChangeForm.vv_newPassword')">{{ $t('Password_Validation_1') }}</span>
                            </div>
                            <div class="form-group">
                                <label for="newPasswordRepeat">{{ $t('New Password Repeat') }}</label>
                                <input type="password" class="form-control" id="newPasswordRepeat" name="vv_newPasswordRepeat" v-validate="'required|confirmed:vv_newPassword'" v-model="formFields.newPasswordRepeat" data-vv-as="vv_newPasswordRepeat">
                                <span class="small text-danger" v-show="errors.has('passwordChangeForm.vv_newPasswordRepeat')">{{ $t('Password_Validation_No_Match_1') }}</span>
                            </div>
                            <div class="form-group mt-4">
                                <button type="button" class="btn btn-primary" @click="submit">{{ $t('Change') }}</button>
                                <router-link tag="a" :to="{ name: 'profile-password-reset-init' }" class="small mb-0 ml-2">{{ $t('Forgot Password?') }}</router-link>
                            </div>
                        </form>

                        <common-box-links></common-box-links>
                    </div>

                    <common-box-footer></common-box-footer>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { AccountService } from '../common/services/api.service';
import AccountAccessSharedMixin from './Mixin/AccountAccessSharedMixin';

export default {
    name: 'ProfilePasswordChangeView',
    mixins: [
        AccountAccessSharedMixin
    ],
    data () {
        return {
            // Persistence / Async //////////
            loading: false,
            changeError: null,

            // Form //////////
            formFields: {
                password: '',
                newPassword: '',
                newPasswordRepeat: ''
            }
        };
    },
    beforeMount () {
        // If is not authenticated, go to 'login'.
        this.goToRouteBasedOnAuth(false, 'login');

        // Check auth.
        this.checkAuthRemoteAsync();
    },
    methods: {
        submit () {
            // Validate Form.
            this.$validator.validateAll('passwordChangeForm').then(valid => {
                // If form is valid, proceed.
                if (valid) {
                    // Start loader.
                    this.loading = true;

                    // Reset error.
                    this.changeError = null;

                    // Prepare DTO.
                    const dto = {
                        currentPassword: this.formFields.password,
                        newPassword: this.formFields.newPassword
                    };

                    // Make request to Zeus to sign up new account.
                    AccountService.changePassword(dto).then(({ data }) => {
                        this.toastDefault(this.$t('Password Changed'), false);

                        // Redirect to Profile.
                        // This redirect is necessary in order to reload the store and give the backend some time.
                        // It known it's funny but it is what it is.
                        setTimeout(function () {
                            window.location.replace('/profile');
                        }, 1500);
                    }).catch((reason) => {
                        // Set error.
                        this.changeError = reason;

                        // Stop Loader.
                        this.loading = false;
                    }).finally(() => {
                        // Stop Loader.
                        // this.loading = false; // We do not want to display the form after a successful request.
                    });
                }
            });
        }
    }
};
</script>
